@import '../general/color';

:root{
    --headerHeight: 109px;

    @media (max-width: 1200px) {
        --headerHeight: 99px;
    }
    @media (max-width: 992px) {
        --headerHeight: 87.5px;
    }
}

html {
    scroll-padding-top: var(--headerHeight);
}

#home {
    overflow: hidden;

    .container {
        @media (max-width: 992px) {
            --bs-gutter-x: 40px;
        }
    }
    .main-banner {
        position: relative;
        overflow: hidden;
        border-radius: 150px 0 0 0;
        height: Min(calc(100vh - var(--headerHeight)), 769px);
        min-height: calc(590px - var(--headerHeight));
        width: 94.1vw;
        margin-inline: auto;

        @media (max-height: 700px) {
            border-radius: 100px 0 0 0;
        }
        @media (min-width: 1920px) {
            max-width: 1806.72px;
        }
        @media (max-width: 992px) {
            margin-top: 31px;
            height: auto;
            max-height: 100vh;
            min-height: 280px;
            border-radius: 60px 0 0 0 !important;
            width: 96vw;
        }
        .container:not(.pagination-wrapper .container) {
            height: 100%;
            display: flex;
            align-items: center;

            @media (max-width: 992px) {
                min-height: 280px;
                justify-content: center;
            }
        }
        .wrapper {
            max-width: 469px;
            translate: 0 -30px;

            @media (max-height: 700px) {
                translate: 0 -10px;
            }
            @media (max-width: 992px) {
                max-width: calc(283px + 40px);
                padding: 30px 20px 30px;
                translate: 0 -8px !important;
            }
            h1 {
                color: #fff;
                font-size: 85px;
                font-weight: 700;
                line-height: 75px;
                letter-spacing: -0.02em;

                @media (max-width: 1400px) {
                    font-size: 75px;
                }
                @media (max-width: 992px) {
                    font-size: 44px;
                    line-height: 38px;
                    max-width: 267px;
                    margin-inline: auto;
                }
                i > strong {
                    font-size: 200px;

                    @media (max-width: 1400px) {
                        font-size: 190px;
                    }
                    @media (max-width: 992px) {
                        font-size: 100px;
                        line-height: 30px;
                    }
                }
            }
            p {
                color: #fff;
                font-weight: 300;
                max-width: 392px;
                margin-block-start: 30px;

                @media (max-height: 700px) {
                    margin-block-start: 16px;
                }
                @media (max-width: 992px) {
                    line-height: 16px;
                    margin-block-start: 10px !important;
                }
            }
            .btn-wrapper {
                margin-block-start: 39px;
                max-width: 223px;

                @media (max-height: 700px) {
                    margin-block-start: 25px;
                }
                .main-btn {
                    height: 63px;
                }
            }

        }
        .pagination-wrapper {
            position: absolute;
            left: 0;
            bottom: 62px;
            z-index: 5;

            @media (max-height: 700px) {
                bottom: 8px;
            }
            @media (max-width: 992px) {
                bottom: 10px;
            }
            .swiper-pagination-bullet {
                @media (max-width: 992px) {
                    width: 10px;
                    height: 10px;
                }
            }
        }
        .swiper {
            max-width: 100%;
            height: 100%;

            &, &-slide {
                @media (max-width: 992px) {
                    min-height: 280px;
                }
            }
            &-slide {
                position: relative;
                height: 100%;

                &::after {
                    content: '';
                    position: absolute;
                    isolation: isolate;
                    inset: 0;
                    background: linear-gradient(105.28deg, #000000 -6.57%, rgba(0, 0, 0, 0) 60.62%);
                    mix-blend-mode: multiply;
                    z-index: -2;

                    @media (max-width: 992px) {
                        background: linear-gradient(105.28deg, #000000 -10.57%, rgba(0, 0, 0, 0) 90%);
                    }
                }
                .video {
                    position: absolute;
                    inset: 0;
                    z-index: -3;

                    video {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
            &-pagination {
                position: unset;
            }
        }
        .socials {
            position: absolute;
            left: 130px;
            top: 134px;
            z-index: 2;

            display: flex;
            flex-direction: column;
            gap: 30px;
            align-items: center;

            @media (max-width: 1780px) {
                left: 30px;
            }
            @media (max-width: 1550px) {
                left: auto;
                right: 80px;
            }
            a {
                &:hover {
                    svg path {
                        fill: #fff;
                    }
                }
                svg path {
                    transition: all .3s ease-in-out;
                }
            }
        }
        .floating-icon {
            position: absolute;
            bottom: 46px;
            z-index: 2;

            display: flex;
            justify-content: center;
            width: 100%;
            pointer-events: none;

            @media (max-height: 700px) {
                bottom: 15px;
            }
            @media (max-width: 992px) {
                display: none;
            }
            svg {
                pointer-events: all;
                cursor: pointer;
                height: 53px;
                width: 35px;

                rect, path { 
                    stroke: #fff; 
                }
                path {
                    animation-duration: 2.2s;
                    animation-iteration-count: infinite;
                    animation-name: scroll;
                    animation-timing-function: cubic-bezier(.15,.41,.69,.94);
                    border-radius: 21.93px;
                    height: 10px;
                    width: 6px;
    
                    @keyframes scroll {
                        0% {
                            opacity: 0;
                        }
                        10% {
                            transform: translateY(0);
                            opacity: 1;
                        }
                        100% {
                            transform: translateY(15px);
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }
    .about {
        padding-block: 136px 172px;

        @media (max-width: 992px) {
            padding-block: 31px 50px;
        }        
        .row {
            --bs-gutter-x: 124px;
            --bs-gutter-y: 25px;
            
            @media (max-width: 1400px) {
                --bs-gutter-x: 60px;
            }
            @media (max-width: 992px) {
                --bs-gutter-x: 0;
            }
        }
        &--title {
            h2 {
                max-width: 560px;

                @media (max-width: 992px) {
                    max-width: 335px;
                    margin-inline: auto;
                    line-height: 30px;

                    br {
                        display: none;
                    }
                }
            }
        }
        &--text {
            @media (max-width: 992px) {
                padding-inline: 16px;

                p {
                    text-align: justify;
                }
            }
            .btn-wrapper {
                margin-top: 50px;

                @media (max-width: 992px) {
                    margin-top: 43px;
                }
                .main-btn {
                    max-width: 308px;
                    height: 63px;

                    @media (max-width: 992px) {
                        max-width: 100%;
                    }
                }
            }
        }
    }
    .catalog {
        padding-block: 151px 150px;
        position: relative;
        overflow: hidden;
        background-color: $gray-100;

        @media (max-width: 992px) {
            padding-block: 45px 97px;
        }
        &--text {
            max-width: 313px;
            display: flex;
            flex-direction: column;
            height: 100%;

            @media (max-width: 992px) {
                max-width: 100%;

                p {
                    padding-inline: 16px;
                    text-align: justify;
                }
            }
            h2 {
                margin-bottom: 35px;

                @media (max-width: 992px) {
                    font-size: 28px;
                    line-height: 30px;
                    margin-bottom: 30px;
                }
            }
        }
        &--slider {
            position: absolute;
            top: 151px;
            right: 124px;
            width: fit-content;

            @media (min-width: 1921px) {
                width: 100%;
            }
            @media (max-width: 1750px) {
                right: 50px;
            }
            @media (max-width: 1570px) {
                right: 0;
            }
            @media (max-width: 1460px) {
                right: 124px;
            }
            @media (max-width: 1200px) {
                right: 15px;
            }
            @media (max-width: 992px) {
                position: unset;
                margin-top: 33px;
                padding-inline: 25px;
                max-width: 100%;
            }
            .swiper {
                max-width: 1059px;

                @media (max-width: 1460px) {
                    max-width: calc((343.13px * 2) + 15px);
                }
                @media (max-width: 992px) {
                    max-width: 100%;
                }
                &-slide {
                    width: fit-content;
                }
            }
        }
        &--item {
            position: relative;
            overflow: hidden;
            isolation: isolate;
            cursor: pointer;

            max-width: 343.1px;
            height: 430.13px;
            border-radius: 0 0 20px 0;

            display: flex;
            align-items: end;

            @media (max-width: 992px) {
                max-width: 100%;
            }
            &:hover {
                .bg-img {
                    scale: 1.1;
                }
                .tag {
                    background-color: $green-accent-400;

                    & > img {
                        filter: brightness(0) invert(1);
                    }
                }
            }
            .tag {
                position: absolute;
                top: 24px;
                left: 34px;
                z-index: 1;
                width: 43px;
                height: 43px;
                background-color: #fff;
                border-radius: 50%;
                transition: all .3s ease-in-out;

                display: grid;
                place-items: center;

                & > img {
                    width: 25px;
                    height: 25px;
                    transition: inherit;
                }
            }
            &::after {
                content: '';
                position: absolute;
                left: 0;
                bottom: 0;
                width: 100%;
                height: 50%;
                background: linear-gradient(180deg, rgba(128, 128, 0, 0) 0%, #121212 100%);
                mix-blend-mode: multiply;
                z-index: -1;
            }
            .wrapper {
                position: relative;
                z-index: 2;
                width: 100%;

                p {
                    color: #fff;
                    font-weight: 200;
                    line-height: 25px;
                    max-width: 202px;
                    margin-inline: 34.9px;

                    strong {
                        color: inherit;
                        font-weight: 700;
                    }
                }
                .btn-wrapper {
                    margin-top: 22px;
                    display: flex;
                    justify-content: end;
                    padding-left: 32px;

                    .main-btn {
                        padding-inline: 20px;
                        max-width: 100%;
                        width: 100%;

                        &:hover {
                            translate: none;
                        }
                    }
                }
            }
        }
        .swiper-pagination-2 {
            position: unset;
            margin-top: 98px;

            @media (max-width: 992px) {
                position: absolute;
                margin-top: 0;
                bottom: 49px;
            }
            .swiper-pagination-bullet {
                border-color: $gray-900;
            }
        }
    }
    .infinite-text {
        padding-block: 79px 106px;

        @media (max-width: 992px) {
            padding-block: 36px 34px;
        }
    }
    .gallery {
        &-wrapper {
            .row {
                --bs-gutter-x: 31px;
                --bs-gutter-y: 23.9px;
            }
        }
        &--image {
            position: relative;
            overflow: hidden;
            cursor: pointer;

            &:hover {
                img:not(.play) {
                    scale: 1.1;
                }
            }
            img:not(.play) {
                width: 100%;
                height: 100%;
                object-fit: cover;
                transition: all .3s ease-in-out;
            }
            .play {
                position: absolute;
                top: 50%;
                left: 50%;
                translate: -50% -50%;
                z-index: 1;
            }
            &.g-1 {
                height: 363.17px;
                border-radius: 40px 0 0 0;
            }
            &.g-2 {
                height: 500.02px;
                border-radius: 0 0 40px 0;
            }
            &.g-3 {
                height: 668px;
            }
        }
        &--text {
            max-width: 514px;
            padding-top: 27px;

            @media (max-width: 992px) {
                max-width: 100%;
                padding-top: 0;

                p {
                    text-align: justify;
                    padding-inline: 17px;
                }
            }
            h2 {
                max-width: 357px;
                margin-bottom: 45px;

                @media (max-width: 992px) {
                    max-width: 343px;
                    margin-inline: auto;
                    margin-bottom: 25px;

                    br {
                        display: none;
                    }
                    & > strong {
                        &:not(:first-of-type) {
                            &::after {
                                content: "\A";
                                white-space: pre;
                            }
                        }
                    }
                }
                strong:not(i > strong) {
                    font-weight: 700;
                }
            }
            p + p {
                margin-top: 30px;
            }
        }
        &-mobile {
            margin-bottom: 49px;

            .swiper {
                &-slide {
                    position: relative;
                    height: 368px;
                    border-radius: 50px 0 0 0;
                    overflow: hidden;

                    img:not(.play) {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                    .play {
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        translate: -50% -50%;
                        z-index: 1;
                        
                        width: 46px;
                        height: 46px;
                    }
                }
                &-pagination-4 {
                    margin-top: 24px;

                    .swiper-pagination-bullet {
                        border-color: $gray-900;
                    }
                }
            }
        }
    }
    .infinite-logos {
        padding-block: 180px 118px;
        
        @media (max-width: 992px) {
            padding-block: 20px 50px;
        }
    }
    .promos {
        background-color: $gray-100;
        padding-block: 134px;

        @media (max-width: 992px) {
            background-color: #fff;
            padding-block: 0 56px;
        }
        &--slider {
            .swiper {
                &-slide {
                    position: relative;
                    overflow: hidden;
                    border-radius: 0 100px 0 0;
                    height: 516px;

                    display: flex;
                    align-items: end;

                    @media (max-width: 992px) {
                        border-radius: 0 50px 0 0;
                    }
                    &::after {
                        content: '';
                        isolation: isolate;
                        position: absolute;
                        bottom: -145px;
                        width: 100%;
                        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 6.79%, #000000 77.74%);
                        height: 340px;
                        z-index: -1;
                    }
                    .btn-wrapper {
                        max-width: 237px;
                        margin-inline: auto;
                        margin-bottom: 180px;

                        @media (max-width: 992px) {
                            margin-bottom: 90px;
                        }
                        .main-btn {
                            padding-inline: 32px;
                        }
                    }
                }
                &-pagination-3 {
                    position: absolute;
                    bottom: 46px;
                    z-index: 1;

                    @media (max-width: 992px) {
                        bottom: 26px;
                    }
                }
            }
        }
    }
    .location {
        padding-block: 21px 0;
        position: relative;

        display: block;
        width: Min(100%, 1920px);
        margin-inline: auto;

        @media (max-width: 992px) {
            padding-block: 0;
        }
        &::after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            background-color: $green-accent-400;
            width: Min(60vw, 1137px);
            height: 519px;
            z-index: -1;

            @media (max-width: 1200px) {
                width: 58vw;
            }
            @media (max-width: 992px) {
                top: auto;
                bottom: 47px;
                width: Min(311px, 70vw);
                height: 259px;
            }
        }
        &--text {
            max-width: 385px;
            padding-top: 61px;

            @media (max-width: 992px) {
                max-width: 100%;
                padding-top: 0;

                h2 {
                    max-width: 280px;
                    margin-inline: auto;

                    br {
                        display: none;
                    }
                    i {
                        &::before {
                            content: "\A";
                            white-space: pre;
                        }
                    }
                }
            }
            & > div > p {
                margin-block: 30px 50px;
                
                @media (max-width: 992px) {
                    margin-block: 20px;
                    text-align: justify;
                    padding-inline: 16px;
                }
            }
            .infos {
                ul { margin: 0; }

                li {
                    @media (max-width: 992px) {
                        flex-direction: column;
                        align-items: center;
                    }
                    .icon {
                        width: 47px;
                        height: 47px;
                        background-color: $green-accent-300;
                        border-radius: 50%;

                        display: grid;
                        place-items: center;

                        @media (max-width: 992px) {
                            width: 37px;
                            height: 37px;
                        }
                        svg {
                            width: 27px;
                            height: 27px;

                            @media (max-width: 992px) {
                                width: 20px;
                                height: 20px;
                            }
                        }
                    }
                    .text {
                        &:last-child {
                            & > p:not(:first-of-type) {
                                color: $green-accent-400
                            }
                        }
                        a, p {
                            color: $gray-900;
                            font-size: 18px;
                            font-weight: 500;
                            line-height: 20px;

                            @media (max-width: 992px) {
                                font-size: 16px;
                                line-height: 20px;
                                text-align: center;
                            }
                        }
                        a {
                            display: block;
                            color: $green-accent-400;

                            strong {
                                font-weight: 700;
                            }
                        }
                    }
                }
                li + li {
                    margin-top: 38px;

                    @media (max-width: 992px) {
                        margin-top: 34px;
                    }
                }
            }
        }
        &--map {
            position: relative;
            overflow: hidden;
            isolation: isolate;

            border-radius: 0 100px 0 0;
            width: Min(100%, 708px);
            height: 814px;

            @media (max-width: 992px) {
                margin-top: 81px;
                height: 278px;
                border-radius: 0 50px 0 0;
            }
            #mapa {
                width: 100%;
                height: 100%;
            }
            .leaflet {
                &-marker-icon {
                    position: relative;

                    @media (max-width: 992px) {
                        max-width: 60px !important;
                        max-height: 60px !important;
                    }
                }
                &-popup {
                    width: Min(391px, 21vw);

                    @media (max-width: 992px) {
                        width: Min(219px, 70vw);
                    }
                    &-content {
                        width: 100% !important;
                        margin: 0 !important;
                        padding-inline: 33px;

                        &-wrapper {
                            box-shadow: 0px 10px 20px 0px #00000040;
                            
                            p {
                                color: $gray-900;
                                font-size: 18px;
                                font-weight: 700;
                                line-height: 25px;
                                text-align: center;
                                width: 100%;
                                
                                @media (max-width: 992px) {
                                    font-size: 12px;
                                    line-height: 18px;
                                }
                                a {
                                    color: inherit;
                                    text-decoration: none;
                                }
                            }
                        }
                    }
                }
            }
            .main-btn {
                position: absolute;
                bottom: 29px;
                left: 38px;
                padding-inline: 32px;
                z-index: 1000;

                @media (max-width: 992px) {
                    left: 22px;
                    bottom: 10px;

                    padding-block: 9px;
                    padding-inline: 18px;
                }
            }
        }
    }

    p {
        color: $gray-400;
        font-size: 18px;
        font-weight: 500;
        line-height: 30px;

        @media (max-width: 992px) {
            font-size: 14px;
            line-height: 24px;
        }
        strong {
            color: $green-accent-300;
            font-weight: 700;
        }
    }
    h2 {
        color: $gray-900;
        font-size: 45px;
        font-weight: 500;
        line-height: 50px;
        letter-spacing: -0.02em;

        @media (max-width: 992px) {
            font-size: 20px;
            line-height: 24px;
        }
        strong {
            color: $green-accent-400;
            font-weight: inherit;
        }
        i > strong {
            display: inline-block;
            color: $green-accent-400;
            font-size: 150px;
            translate: 8px 0;

            @media (max-width: 992px) {
                font-size: 50px;
                line-height: 24px;
                translate: none;
            }
        }
    }
    i > strong {
        font-family: "Autography";
        font-weight: normal;
    }
    .swiper {
        &-pagination {
            &-bullet {
                width: 14px;
                height: 14px;
                background: transparent;
                border: 2px solid #fff;
                opacity: 1 !important;
                transition: all .3s ease-in-out;

                &-active {
                    background: $green-accent-400 !important;
                    border-color: $green-accent-400 !important;
                }
            }
        }        
    }
    .bg-img {
        position: absolute;
        inset: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: -3;
        transition: all .3s ease-in-out;
    }
    a { text-decoration: none; }
}